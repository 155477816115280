<template>
  <div class="wrapper container" style="padding-bottom: 40px">
    <div class="justify-center row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="d-flex justify-center p-1 pt-3">
          <TaskImage
            :level="task.level"
            :image_path="task.icon_path"
            style="width: 150px"
          />
        </div>
        <div style="color: #404040">
          <div
            style="font-size: 23pt"
            class="text-center font-weight-bold mt-2 mb-6"
          >
            {{ task.name }}
          </div>
        </div>
        <div v-if="task.level == 0" class="pt-3">
          <v-btn
            color="success"
            elevation="2"
            block
            large
            @click="startScan"
            class="mt-3 mb-4"
            >SCAN QR-KODE</v-btn
          >
        </div>
        <div v-else-if="task.level == 1" class="pt-3">
          <div v-if="task.video_url" class="mb-3">
            <v-responsive :aspect-ratio="16 / 9">
              <youtube
                :video-id="videoId"
                player-width="100%"
                player-height="100%"
                style="height: 100%"
              />
            </v-responsive>
          </div>
          <div v-html="task.description"></div>
          <div v-if="['question', 'open-question'].includes(task.type)">
            <div>
              <div class="font-weight-bold mt-4">Opgave:</div>
              <div v-html="task.task" id="taskText"></div>
            </div>

            <div class="mt-3">
              <v-text-field
                v-model="answer"
                label="Svar"
                solo
                class="mb-3"
                hide-details
                v-on:keyup.enter="sendAnswer"
              ></v-text-field>
              <v-btn
                color="secondary"
                elevation="2"
                block
                large
                @click="sendAnswer"
                class="mb-4"
                >Send</v-btn
              >
            </div>
          </div>
        </div>
        <div v-else-if="task.level == 2" class="pt-3">
          <div class="text-center mb-4">
            Tak for dit svar på opgaven. Du har fået dit Guld-badge!
          </div>

          <div class="text-center">
            <v-btn
              color="success"
              elevation="2"
              large
              link
              :to="{
                name: 'activity',
                params: {
                  slug: this.$route.params.slug,
                  activitySlug: this.$route.params.activitySlug,
                },
              }"
              class="mb-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
                style="height: 24px; margin-right: 24px"
              >
                <path
                  fill="white"
                  d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
                />
              </svg>
              <span style="padding-top: 1px"> Find det næste sted </span>
            </v-btn>
          </div>
        </div>
      </div>
      <v-bottom-sheet v-model="sheet" eager>
        <v-sheet class="text-center" style="position: relative">
          <QRCodeScanner :qrbox="200" :fps="10" @result="onScan" v-if="sheet" />
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <portal to="app-bar-name">{{ church.name }}</portal>
  </div>
</template>

<script>
import TaskImage from "../components/TaskImage";
import QRCodeScanner from "./components/QRCodeScanner.vue";
import { getIdFromURL } from "vue-youtube-embed";
export default {
  name: "home-page",
  data() {
    return {
      sheet: false,
      answer: "",
      forceRefresh: 0,
    };
  },

  computed: {
    church() {
      return this.$store.getters["churches/getChurch"](this.$route.params.slug);
    },
    task() {
      this.forceRefresh; // just referencing it is enough!
      return this.$store.getters["churches/getTask"](
        this.$route.params.slug,
        this.$route.params.activitySlug,
        this.$route.params.taskId
      );
    },
    videoId() {
      return getIdFromURL(this.task.video_url);
    },
  },

  methods: {
    onScan(decodedText, decodedResult) {
      console.log(decodedText, decodedResult);
      this.onDecode(decodedText);
    },
    startScan() {
      this.sheet = true;
      window.scrollTo(0, 0);
    },
    onDecode(decodedString) {
      console.log(decodedString);
      if (
        decodedString ===
        `https://kirkebesøg.dk/${this.$route.params.slug}/scan/${this.task.identifier}`
      ) {
        this.$store.dispatch("churches/setLevel", {
          slug: this.$route.params.slug,
          activitySlug: this.$route.params.activitySlug,
          taskId: this.$route.params.taskId,
          level: 1,
        });
        this.forceRefresh++;
      } else {
        this.$store.dispatch(
          "snackbar/showSnack",
          {
            text: "QR koden kunne ikke genkendes",
            color: "warning",
            timeout: 2000,
          },
          { root: true }
        );
      }
      this.sheet = false;
    },
    sendAnswer() {
      if (this.answer === "") {
        this.$store.dispatch(
          "snackbar/showSnack",
          {
            text: "Du mangler at skrive et svar ;)",
            color: "warning",
            timeout: 2000,
          },
          { root: true }
        );
        return;
      }
      if (this.task.type === "open-question") {
        this.$store.dispatch("churches/setLevel", {
          slug: this.$route.params.slug,
          activitySlug: this.$route.params.activitySlug,
          taskId: this.$route.params.taskId,
          level: 2,
        });
        this.forceRefresh++;
        return;
      }

      const answers = this.task.answer.toLowerCase().split(",");
      console.log(answers);
      console.log(this.answer);
      if (answers.includes(this.answer.toLowerCase().trim())) {
        this.$store.dispatch("churches/setLevel", {
          slug: this.$route.params.slug,
          activitySlug: this.$route.params.activitySlug,
          taskId: this.$route.params.taskId,
          level: 2,
        });
        this.forceRefresh++;
      } else {
        this.$store.dispatch(
          "snackbar/showSnack",
          {
            text: "Prøv igen ;)",
            color: "warning",
            timeout: 2000,
          },
          { root: true }
        );
      }
    },
  },
  components: { TaskImage, QRCodeScanner },
};
</script>
<style>
#taskText img {
  max-width: 100%;
}
</style>
