import churches from "../../api/churches";
import _ from "lodash";

// initial state
const state = () => ({
  churches: {},
});

// getters
const getters = {
  getChurch: (state) => (slug) => {
    if (!(slug in state.churches) || state.churches[slug] === null) {
      return 404;
    }
    let church = { ...state.churches[slug] };

    return church;
  },
  getActivity: (state) => (slug, activitySlug) => {
    let activity = {
      ...state.churches[slug].activities[activitySlug],
    };
    console.log("getActivity", activity);
    return activity;
  },
  getTask: (state) => (slug, activitySlug, id) => {
    let task = {
      ...state.churches[slug].activities[activitySlug].tasks[id],
    };
    console.log("getTask", task);
    return task;
  },
};

// actions
const actions = {
  getChurch({ commit }, { slug }) {
    console.log("getChurchSlug", slug);
    if (slug) {
      churches.getChurch(slug, (church) => {
        console.log("getChurch", church);
        commit("addChurch", { church, slug });
      });
    }
  },
  setLevel({ commit, dispatch }, { slug, activitySlug, taskId, level }) {
    console.log("setLevel action", slug, activitySlug, taskId, level);
    commit("setLevel", { slug, activitySlug, taskId, level });
    dispatch("checkHidden", { slug, activitySlug });
    // dispatch(
    //   "snackbar/showSnack",
    //   {
    //     text: "Tillykke med dit nye badge.",
    //     color: "success",
    //     timeout: 8000,
    //   },
    //   { root: true }
    // );
    dispatch("confetti/showConfetti", { level }, { root: true });
  },
  checkHidden({ commit, dispatch, state }, { slug, activitySlug }) {
    console.log("checkHidden", state, slug, activitySlug);

    let tasks = state.churches[slug].activities[activitySlug].tasks;

    let allComplete = Object.assign([], tasks)
      .filter((task) => task.secret == 0)
      .every(
        (task) =>
          (["question", "open-question"].includes(task.type) &&
            task.level == 2) ||
          (!["question", "open-question"].includes(task.type) && task.level > 0)
      );

    console.log("checkHidden", allComplete);

    if (allComplete) {
      commit("unhide", { slug, activitySlug });

      dispatch(
        "snackbar/showSnack",
        {
          text: "Du har fået et hemmeligt badge",
          color: "success",
          timeout: 8000,
        },
        { root: true }
      );
    }
  },
};

// mutations
const mutations = {
  addChurch(state, { church, slug }) {
    console.log("addChurch", state, church, slug);
    let newChurch = { ...church };
    newChurch.activities.forEach((activity) => {
      console.log(activity);
      activity.tasks.map((task) => {
        if (
          state.churches[slug] &&
          state.churches[slug].activities[activity.slug] &&
          state.churches[slug].activities[activity.slug].tasks[task.id]
        ) {
          var old_task =
            state.churches[slug].activities[activity.slug].tasks[task.id] ??
            null;
          task.level = old_task ? old_task.level ?? 0 : 0;
          task.hidden = old_task ? old_task.hidden ?? task.secret : task.secret;
        } else {
          task.level = 0;
          task.hidden = task.secret;
        }
        return task;
      });
      activity.tasks = _.keyBy(activity.tasks, "id");
    });
    newChurch.activities = _.keyBy(newChurch.activities, "slug");

    state.churches[slug] = newChurch;
  },
  setLevel(state, { slug, activitySlug, taskId, level }) {
    console.log("setLevel", state, slug, activitySlug, taskId, level);

    state.churches[slug].activities[activitySlug].tasks[taskId].level = level;
  },
  unhide(state, { slug, activitySlug }) {
    console.log("unhide", state, slug, activitySlug);

    let tasks = Object.assign(
      [],
      state.churches[slug].activities[activitySlug].tasks
    ).filter((task) => task.hidden);

    tasks.forEach(function (task) {
      task.hidden = 0;
      task.level = 1;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
